var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import PublicRoomContext from "../../../../application/Context/PublicRoomContext";
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import fr from 'javascript-time-ago/locale/fr-CA.json';
import { useTranslation } from "react-i18next";
TimeAgo.addDefaultLocale(fr);
TimeAgo.addLocale(en);
export var Channel = function (_a) {
    var chanel = _a.chanel;
    var t = useTranslation(['public-room']).t;
    var _b = React.useContext(PublicRoomContext), handleChannelSelection = _b.handleChannelSelection, selectedChannel = _b.selectedChannel;
    var latestMessageDate = chanel.threads.reduce(function (acc, curr) {
        var latestMessage = curr.messages.reduce(function (msgAcc, msgCurr) {
            if (msgCurr.created_at && new Date(msgCurr.created_at) > new Date(msgAcc)) {
                return msgCurr.created_at;
            }
            return msgAcc;
        }, '1970-01-01T00:00:00.000Z');
        if (latestMessage && new Date(latestMessage) > new Date(acc)) {
            return latestMessage;
        }
        return acc;
    }, '1970-01-01T00:00:00.000Z');
    return (_jsxs("li", __assign({ onClick: function () { return handleChannelSelection(chanel.id); }, className: selectedChannel === chanel.id ? "active" : "" }, { children: [_jsx("p", __assign({ className: "fw-bold mb-2" }, { children: chanel.name })), _jsx("div", __assign({ className: "d-flex" }, { children: latestMessageDate != '1970-01-01T00:00:00.000Z' ?
                    _jsxs(_Fragment, { children: [_jsxs("p", { children: [t("Dernier message"), " "] }), _jsx("span", __assign({ className: "ms-auto" }, { children: _jsx(ReactTimeAgo, { date: new Date(latestMessageDate), locale: "en-us", timeStyle: "twitter" }) }))] })
                    :
                        _jsxs(_Fragment, { children: [_jsxs("p", { children: [t("y a pas de message sur cet chaîne"), "  "] }), _jsx("span", { className: "ms-auto" })] }) }))] })));
};
