var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ChannelsList } from "../components/react-components/public-room-components/Channels/ChannelsList";
import { PublicRoomProvider } from "./Context/PublicRoomContext";
import { ThreadsList } from "../components/react-components/public-room-components/Threads/ThreadsList";
import { CreateThread } from "../components/react-components/public-room-components/Actions/CreateThread";
import { SearchBar } from "../components/react-components/public-room-components/SearchBar";
import { ThemeProvider } from "styled-components";
import { Title } from "../components/react-components/public-room-components/Channels/Title";
import { Tab } from "../components/react-components/public-room-components/Tab/Tab";
import { MessagesHeader } from "../components/react-components/public-room-components/Messages/MessagesHeader";
import Theme from "../components/react-components/Styles/Theme";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18n from "../i18n/i18n";
import { PageLoading } from "../components/react-components/shared-components/Loading/PageLoading";
var PublicRoom = function () {
    React.useEffect(function () {
        var _a;
        // @ts-ignore
        var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
        if ((langCode === null || langCode === void 0 ? void 0 : langCode.length) === 2) {
            i18next.changeLanguage(langCode);
        }
    }, []);
    var backToChannelsNav = function () {
        var k = document.getElementsByClassName("pr-row-right")[0];
        k.style.transform = "translate(100%)";
    };
    // #public-room
    if (window.innerWidth < 769) {
        if (document.getElementById("public-room")) {
            document.querySelectorAll("body")[0].style.overflow = "hidden";
        }
    }
    return (_jsx(Suspense, __assign({ fallback: _jsx(PageLoading, {}) }, { children: _jsx(I18nextProvider, __assign({ i18n: i18n }, { children: _jsx(PublicRoomProvider, { children: _jsx(ThemeProvider, __assign({ theme: Theme }, { children: _jsx(React.Fragment, { children: _jsx("div", __assign({ className: "container" }, { children: _jsxs("div", __assign({ className: "pr-row" }, { children: [_jsxs("div", __assign({ className: "pr-row-left" }, { children: [_jsx(Tab, {}), _jsx("div", __assign({ className: "pr-tabs-content" }, { children: _jsxs("div", __assign({ className: "pr-tab-cont active ps-3" }, { children: [_jsx(CreateThread, {}), _jsx(SearchBar, {}), _jsx(Title, {}), _jsx(ChannelsList, {})] })) }))] })), _jsxs("div", __assign({ className: "pr-row-right" }, { children: [_jsxs("div", __assign({ className: "pr-row-right-nav", onClick: function (e) { return backToChannelsNav(e); } }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/arrow-left-blue.svg"), alt: "arrow left", className: "icon-arrow" }), "Retour \u00E0 la liste des cha\u00EEnes"] })), _jsx(MessagesHeader, {}), _jsx(ThreadsList, {})] }))] })) })) }) })) }) })) })));
};
var domNode = document.querySelector("#public-room");
if (domNode) {
    var root = createRoot(domNode);
    root.render(_jsx(PublicRoom, {}));
}
