var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import PublicRoomContext from '../../../../application/Context/PublicRoomContext';
import { useTranslation } from "react-i18next";
export var ThreadMessageToggler = function (_a) {
    var threadId = _a.threadId, threadMessages = _a.threadMessages;
    var t = useTranslation(['public-room']).t;
    var _b = React.useContext(PublicRoomContext), messagesToShow = _b.messagesToShow, getMessages = _b.getMessages, selectedChannel = _b.selectedChannel, messages = _b.messages;
    var responseNbr = threadMessages.length;
    var currentMessage = messages.filter(function (obj) { return obj.thread_id === threadId; });
    return (_jsxs(React.Fragment, { children: [_jsxs("div", __assign({ className: "post-comment-header public-room-component-responses", id: "msg-".concat(threadId), onClick: function (e) { return getMessages(e, selectedChannel, threadId, responseNbr); } }, { children: [currentMessage[0] ? currentMessage[0].responseNbr : responseNbr, " ", t("réponse/s"), messagesToShow === threadId ? (_jsxs("div", { children: [_jsxs("span", __assign({ className: 'me-1' }, { children: [" ", t("Réduire tous"), "  "] })), _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/arrow-up-blue.svg"), alt: "hide all" })] })) : (_jsxs("div", { children: [_jsxs("span", __assign({ className: 'me-1 public-room-component-display-more-result' }, { children: [t("Afficher tous"), " "] })), _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/arrow-down-blue.svg"), alt: "show all" })] }))] })), messagesToShow !== threadId ? (_jsxs("div", __assign({ className: 'post-comment-footer', onClick: function (e) { return getMessages(e, selectedChannel, threadId); } }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/comment.svg"), alt: "comment" }), _jsx("div", { children: t("Commentaires") })] }))) :
                ""] }));
};
