var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ShowMoreText from "react-show-more-text";
import Linkify from 'react-linkify';
export var ThreadContent = function (_a) {
    var id = _a.id, title = _a.title, content = _a.content;
    var MoreButton = function () { return _jsxs("div", __assign({ className: 'learn-more-btn' }, { children: ["Voir plus ", _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/arrow-right-small.svg"), alt: "arrow right" })] })); };
    var LessButton = function () { return _jsxs("div", __assign({ className: 'learn-more-btn' }, { children: ["Voir moins ", _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/arrow-right-small.svg"), alt: "arrrow right" })] })); };
    var componentDecorator = function (href, text, key) { return (_jsx("a", __assign({ className: "linkify__text", rel: "noreferrer", href: href, target: "_blank" }, { children: text }), key)); };
    return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: 'post-content' }, { children: [_jsx("p", { children: _jsx("strong", { children: title }) }), _jsx("div", __assign({ className: "text mini", id: "text-".concat(id) }, { children: _jsx(ShowMoreText
                    /* Default options */
                    , __assign({ 
                        /* Default options */
                        lines: 3, more: _jsx(MoreButton, {}), less: _jsx(LessButton, {}), expanded: false, className: "content-css", width: 700, keepNewLines: false, truncatedEndingComponent: "... " }, { children: _jsx(Linkify, __assign({ componentDecorator: componentDecorator }, { children: content })) })) }))] })) }));
};
