var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from "react-i18next";
export var Tab = function () {
    var _a;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    var t = useTranslation(['public-room']).t;
    return (_jsx(React.Fragment, { children: _jsxs("ul", __assign({ className: "pr-tabs" }, { children: [_jsx("li", { children: _jsx("a", __assign({ href: "/".concat(langCode, "/chat") }, { children: t("Messagerie") })) }), _jsx("li", { children: _jsx("div", __assign({ className: "active" }, { children: t("Salles publiques") })) })] })) }));
};
