var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from "react-i18next";
import { MessageHeader } from './MessageHeader';
import { MessageContent } from './MessageContent';
import { Tooltip } from "react-tooltip";
import { ThreadAvatar } from '../Threads/ThreadAvatar';
export var Message = function (_a) {
    var id = _a.id, message = _a.message, author = _a.author, date = _a.date, likes = _a.likes, likeCount = _a.likeCount, isAuthor = _a.isAuthor, threadId = _a.threadId, lastName = _a.lastName;
    var t = useTranslation(['common']).t;
    return (_jsxs(React.Fragment, { children: [_jsxs("div", __assign({ className: "post-comments-unit" }, { children: [_jsx(MessageHeader, { id: id, threadId: threadId, author: author, lastName: lastName, isAuthor: isAuthor, date: date, likes: likes, likeCount: likeCount }), _jsx(MessageContent, { message: message })] })), _jsx(Tooltip, __assign({ id: "msg" + id, style: { backgroundColor: "#fff", boxShadow: "0px 1px 4px #22365429", color: "#223654", opacity: "1" } }, { children: _jsxs("div", { children: [likes.length > 0 && _jsx("p", __assign({ className: 'mb-2 fw-bold' }, { children: t("Réactions") })), likes.length > 0 ? likes.map(function (like) {
                            return (_jsxs("div", __assign({ className: "post-header-unit-top " }, { children: [like.user.first_name && (_jsx("div", __assign({ className: 'mb-1' }, { children: _jsx(ThreadAvatar, { firstName: like.user.first_name, lastName: like.user.last_name }) }))), _jsx("div", __assign({ className: "post-header-top" }, { children: _jsxs("p", __assign({ className: "auth-name" }, { children: [like.user.first_name, " ", like.user.last_name] })) }))] }), like.id));
                        }) : _jsx(_Fragment, { children: t("No likes") })] }) }))] }));
};
