var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from '../Modals/Modal';
import PublicRoomContext from '../../../../application/Context/PublicRoomContext';
export var ThreadForm = function () {
    var t = useTranslation(['common']).t;
    var _a = useContext(PublicRoomContext), handleHideThreadForm = _a.handleHideThreadForm, createThread = _a.createThread, threadContent = _a.threadContent, threadTitle = _a.threadTitle, handleThreadContent = _a.handleThreadContent, handleThreadTitle = _a.handleThreadTitle, selectedChannel = _a.selectedChannel, threadIsEditing = _a.threadIsEditing, editThread = _a.editThread, threadIdToEdit = _a.threadIdToEdit;
    return (_jsx(React.Fragment, { children: _jsx(Modal, { children: _jsxs("div", __assign({ className: "modal-content" }, { children: [_jsxs("div", __assign({ className: "modal-header" }, { children: [_jsx("h5", __assign({ className: "modal-title" }, { children: t("New Thread") })), _jsx("button", { type: "button", className: "btn-close", onClick: handleHideThreadForm })] })), _jsx("div", __assign({ className: "modal-body" }, { children: _jsxs("form", { children: [_jsxs("div", __assign({ className: "mb-3" }, { children: [_jsx("label", __assign({ htmlFor: "thread-title", className: "col-form-label" }, { children: t("Thread Title:") })), _jsx("input", { type: "text", className: "form-control rounded-0", id: "thread-title", value: threadTitle, onChange: handleThreadTitle })] })), _jsxs("div", __assign({ className: "mb-3" }, { children: [_jsx("label", __assign({ htmlFor: "Content", className: "col-form-label" }, { children: _jsx("span", { children: t("Contenu :") }) })), _jsx("textarea", { className: "form-control rounded-0", id: "Content", value: threadContent, onChange: handleThreadContent })] }))] }) })), _jsxs("div", __assign({ className: "modal-footer" }, { children: [_jsx("button", __assign({ type: "button", className: "btn btn-secondary rounded-0 btn-md", onClick: handleHideThreadForm }, { children: t("Close") })), _jsx("button", __assign({ type: "button", className: "btn btn-primary me-0 btn-md", onClick: function (e) { return threadIsEditing ? editThread(e, selectedChannel, threadIdToEdit) : createThread(e, selectedChannel); } }, { children: t("Send Thread") }))] }))] })) }) }));
};
