import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { SearchBarContainer } from "../shared-components/searchInput/search-input-styles";
import { SearchInput } from "../shared-components/searchInput/SearchInput";
import PublicRoomContext from "../../../application/Context/PublicRoomContext";
import { useTranslation } from "react-i18next";
export var SearchBar = function () {
    var t = useTranslation(['public-room']).t;
    var _a = React.useContext(PublicRoomContext), searchInput = _a.searchInput, handleSearchInput = _a.handleSearchInput, handleSearch = _a.handleSearch, handleKeyDown = _a.handleKeyDown;
    return (_jsx(SearchBarContainer, { children: _jsx(SearchInput, { placeholder: t("Rechercher"), type: "text", value: searchInput, onChange: handleSearchInput, handleSearch: handleSearch, onKeyDown: handleKeyDown }) }));
};
