var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import PublicRoomContext from '../../../../application/Context/PublicRoomContext';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
export var ActionsTooltipMessage = function (_a) {
    var threadId = _a.threadId, messageId = _a.messageId, type = _a.type;
    var t = useTranslation(["common"]).t;
    var _b = useContext(PublicRoomContext), selectedChannel = _b.selectedChannel, setConfirmationModalIsOpen = _b.setConfirmationModalIsOpen, getThreadToEdit = _b.getThreadToEdit, getMessageToEdit = _b.getMessageToEdit;
    var editFunction = type == "thread" ? getThreadToEdit : getMessageToEdit;
    return (_jsxs(React.Fragment, { children: [_jsx(ConfirmationModal, { threadId: threadId, messageId: messageId, type: type }), _jsx("div", __assign({ className: "post-comment-controllers-tooltip" }, { children: _jsxs("div", __assign({ className: 'buttons-wrapper' }, { children: [_jsxs("button", __assign({ onClick: function () { return setConfirmationModalIsOpen(true); } }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/trash-blue-fonce.svg"), alt: "delete" }), t("Delete")] })), _jsxs("button", __assign({ onClick: function (e) { return editFunction(e, selectedChannel, threadId, messageId); } }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/pencil-blue-fonce.svg"), alt: "edite" }), t("Edit")] }))] })) }))] }));
};
