var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from "react-i18next";
export var Title = function () {
    var _a;
    var _b = useTranslation(['common']), t = _b.t, i18n = _b.i18n;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    var _c = __read(React.useState(i18n.language), 2), currentLang = _c[0], setCurrentLang = _c[1];
    React.useEffect(function () {
        if (currentLang !== langCode) {
            i18n.changeLanguage(langCode);
            setCurrentLang(langCode);
        }
    }, [currentLang]);
    return (_jsx(React.Fragment, { children: _jsx("p", __assign({ className: "pr-tabs-title" }, { children: t("Canaux") })) }));
};
