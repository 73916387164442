var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from '../Modals/Modal';
import PublicRoomContext from '../../../../application/Context/PublicRoomContext';
export var ConfirmationModal = function (_a) {
    var threadId = _a.threadId, messageId = _a.messageId, type = _a.type;
    var t = useTranslation(['common']).t;
    var _b = useContext(PublicRoomContext), deleteThread = _b.deleteThread, selectedChannel = _b.selectedChannel, deleteMessage = _b.deleteMessage, confirmationModalIsOpen = _b.confirmationModalIsOpen, setConfirmationModalIsOpen = _b.setConfirmationModalIsOpen;
    return (_jsx(React.Fragment, { children: confirmationModalIsOpen &&
            _jsx(Modal, { children: _jsxs("div", __assign({ className: "modal-content" }, { children: [_jsxs("div", __assign({ className: "modal-header" }, { children: [_jsx("h5", __assign({ className: "modal-title" }, { children: t("Delete") })), _jsx("button", { type: "button", className: "btn-close", onClick: function () { return setConfirmationModalIsOpen(false); } })] })), _jsxs("p", __assign({ className: 'mb-4 mt-4' }, { children: [" ", t("Êtes-vous sûr de vouloir supprimer ?"), " "] })), _jsxs("div", __assign({ className: "modal-footer" }, { children: [_jsx("button", __assign({ type: "button", className: "btn btn-secondary rounded-0", onClick: function () { return setConfirmationModalIsOpen(false); } }, { children: "Close" })), _jsx("button", __assign({ type: "button", className: "btn btn-danger", onClick: function (e) { return type == "thread" ? deleteThread(e, selectedChannel, threadId) : deleteMessage(e, selectedChannel, threadId, messageId); } }, { children: t("Delete") }))] }))] })) }) }));
};
