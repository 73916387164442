var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from "react-i18next";
export var AlertComponent = function (_a) {
    var handleClick = _a.handleClick;
    var t = useTranslation(["common"]).t;
    return (_jsx(React.Fragment, { children: _jsx("div", __assign({ className: "alert-message", "data-color": "yellow", "data-status": "active" }, { children: _jsx("div", __assign({ className: "container" }, { children: _jsxs("div", __assign({ className: "content" }, { children: [_jsxs("div", __assign({ className: "c-center" }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/warning-y.svg"), alt: "warning" }), _jsx("p", { children: t("Please select a channel") })] })), _jsx("div", __assign({ className: "close", onClick: handleClick }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/cross.svg"), alt: "close" }) }))] })) })) })) }));
};
