var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import PublicRoomContext from '../../../../application/Context/PublicRoomContext';
export var MessageForm = function (_a) {
    var threadId = _a.threadId;
    var t = useTranslation(['common']).t;
    var _b = useContext(PublicRoomContext), selectedChannel = _b.selectedChannel, createMessage = _b.createMessage, handleMessage = _b.handleMessage, entredMessage = _b.entredMessage, editMessage = _b.editMessage, messageIsEditing = _b.messageIsEditing, messageIdToEdit = _b.messageIdToEdit;
    return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: 'response-section' }, { children: [_jsx("textarea", { className: 'textarea', name: "", value: entredMessage, placeholder: t("Réponse"), onChange: handleMessage }), _jsx("div", { className: 'blue-line' }), _jsx("div", __assign({ className: 'response-section-controllers' }, { children: _jsx("button", __assign({ className: "btn btn-primary compact", onClick: function (e) { return messageIsEditing ? editMessage(e, selectedChannel, threadId, messageIdToEdit) : createMessage(e, selectedChannel, threadId); } }, { children: t("Send") })) }))] })) }));
};
