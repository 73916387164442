var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Thread } from "./Thread";
import PublicRoomContext from "../../../../application/Context/PublicRoomContext";
import { motion } from "framer-motion";
import { LoadingThread } from "../../shared-components/Loading/LoadingThread";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
export var ThreadsList = function () {
    var _a = React.useContext(PublicRoomContext), threads = _a.threads, hasMoreData = _a.hasMoreData, loadNewThreads = _a.loadNewThreads;
    var t = useTranslation(['public-room']).t;
    return (_jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.2 }, exit: { opacity: 0 } }, { children: _jsx("div", __assign({ className: "posts-section" }, { children: threads.length != 0 ?
                _jsx(InfiniteScroll, __assign({ dataLength: threads.length, next: loadNewThreads, hasMore: hasMoreData, loader: _jsx(LoadingThread, {}), height: 80 + 'vh', endMessage: _jsx("p", __assign({ style: { textAlign: "center", marginTop: "14px" } }, { children: _jsxs("b", { children: [" ", t("Plus de fils")] }) })) }, { children: threads.map(function (thread, i) {
                        return _jsx(Thread, { thread: thread }, i);
                    }) }))
                :
                    _jsx("div", __assign({ className: 'empty-message' }, { children: t("Sélectionnez la chaîne !") })) })) })));
};
