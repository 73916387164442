var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from "react-i18next";
import { ThreadHeader } from './ThreadHeader';
import { ThreadContent } from './ThreadContent';
import { ThreadMessageToggler } from './ThreadMessageToggler';
import PublicRoomContext from '../../../../application/Context/PublicRoomContext';
import { Message } from './../Messages/Message';
import { MessageForm } from '../Messages/MessageForm';
import { ThreadAvatar } from './ThreadAvatar';
import { Tooltip } from "react-tooltip";
export var Thread = function (_a) {
    var _b;
    var thread = _a.thread;
    var t = useTranslation(['common']).t;
    var _c = React.useContext(PublicRoomContext), messagesToShow = _c.messagesToShow, messages = _c.messages;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var f = Intl.DateTimeFormat(langCode, options);
    return (_jsxs("div", __assign({ id: thread.id, "data-chanelid": thread.id }, { children: [_jsx("div", __assign({ className: 'date-display' }, { children: _jsx("div", { children: f.format(new Date(thread.created_at)) }) })), _jsx("div", __assign({ className: 'post-unit' }, { children: _jsxs("div", __assign({ className: 'post-content-col' }, { children: [_jsx(ThreadHeader, { id: thread.id, author: thread.author.first_name, date: thread.created_at, likesCount: thread.like_count, likes: thread.likes, isAuthor: thread.is_author, firstName: thread.author.first_name, lastName: thread.author.last_name }), _jsx(Tooltip, __assign({ id: thread.id, style: { backgroundColor: "#fff", boxShadow: "0px 1px 4px #22365429", color: "#223654", opacity: "1" } }, { children: _jsxs("div", { children: [thread.likes.length > 0 && _jsx("p", __assign({ className: 'mb-2 fw-bold' }, { children: t("Réactions") })), thread.likes.length > 0 ? thread.likes.map(function (like) {
                                        return (_jsxs("div", __assign({ className: "post-header-unit-top " }, { children: [like.user.first_name && (_jsx("div", __assign({ className: 'mb-1' }, { children: _jsx(ThreadAvatar, { firstName: like.user.first_name, lastName: like.user.last_name }) }))), _jsx("div", __assign({ className: "post-header-top" }, { children: _jsxs("p", __assign({ className: "auth-name" }, { children: [like.user.first_name, " ", like.user.last_name] })) }))] }), like.id));
                                    }) : _jsx(_Fragment, { children: t("No likes") })] }) })), _jsx(ThreadContent, { id: thread.id, title: thread.title, content: thread.content }), _jsxs("div", __assign({ className: 'post-comments-section' }, { children: [_jsx(ThreadMessageToggler, { threadId: thread.id, threadMessages: thread.messages }), messages.map(function (msg) {
                                    return msg.thread_id == thread.id ? msg.messages.map(function (threadMsg) {
                                        return messagesToShow == thread.id &&
                                            _jsx(Message, { id: threadMsg.id, threadId: thread.id, message: threadMsg.content, author: threadMsg.author.first_name, lastName: threadMsg.author.last_name, isAuthor: threadMsg.is_author, date: threadMsg.created_at, likes: threadMsg.likes, likeCount: threadMsg.like_count }, threadMsg.id);
                                    }) : "";
                                }), messagesToShow === thread.id && _jsx(MessageForm, { threadId: thread.id }, thread.id)] }))] })) }))] })));
};
