var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef, useContext } from "react";
import PublicRoomContext from "../../../../application/Context/PublicRoomContext";
import { ThreadAvatar } from "./ThreadAvatar";
import { ActionsTooltipThread } from "../Actions/ActionsTooltipThread";
import ReactTimeAgo from "react-time-ago";
import useOutsideClick from "../../shared-components/useOutsideClick";
export var ThreadHeader = function (_a) {
    var id = _a.id, author = _a.author, date = _a.date, likesCount = _a.likesCount, likes = _a.likes, isAuthor = _a.isAuthor, firstName = _a.firstName, lastName = _a.lastName;
    var _b = useContext(PublicRoomContext), currentUserId = _b.currentUserId, likeThread = _b.likeThread, selectedChannel = _b.selectedChannel, unlikeThread = _b.unlikeThread;
    var userLikedThread = likes.some(function (like) { return like.user.id == currentUserId; });
    var _c = __read(React.useState(""), 2), controllersTooltip = _c[0], setControllersTooltip = _c[1];
    // Close the tooltip when user click anywhere 
    var tooltipRef = useRef(null);
    useOutsideClick(tooltipRef, function () {
        setControllersTooltip("");
    });
    var handleControllersTooltip = function (e) {
        var eleId = e.target.getAttribute("data-id");
        if (controllersTooltip === eleId) {
            setControllersTooltip("");
        }
        else {
            setControllersTooltip(eleId);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "post-header" }, { children: [_jsxs("div", __assign({ className: "post-header-unit-top" }, { children: [_jsx(ThreadAvatar, { firstName: firstName, lastName: lastName }), _jsx("div", __assign({ className: "post-header-top" }, { children: _jsx("p", __assign({ className: "auth-name" }, { children: author })) }))] })), _jsxs("div", __assign({ className: "post-controllers", ref: tooltipRef }, { children: [controllersTooltip === "pc-".concat(id) && isAuthor && (_jsx(ActionsTooltipThread, { threadId: id, type: "thread" })), _jsxs("div", __assign({ className: "post-controllers-container" }, { children: [_jsxs("div", __assign({ className: "show-likes-section", "data-tooltip-id": id }, { children: [userLikedThread ? (_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/like-filled.svg"), alt: "like", className: "icon-heart", onClick: function (e) { return unlikeThread(e, selectedChannel, id); } })) : (_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/heart-b.svg"), alt: "like", className: "icon-heart", onClick: function (e) { return likeThread(e, selectedChannel, id); } })), likesCount] })), isAuthor && (_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/vertical-dots.svg"), alt: "dots", "data-id": "pc-".concat(id), onClick: function (e) { return handleControllersTooltip(e); } }))] }))] }))] })), _jsx("p", __assign({ className: "time-ago" }, { children: _jsx(ReactTimeAgo, { date: new Date(date), locale: "en-us", timeStyle: "twitter" }) }))] }));
};
