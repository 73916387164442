var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import PublicRoomContext from "../../../../application/Context/PublicRoomContext";
export var MessagesHeader = function () {
    var _a = useContext(PublicRoomContext), selectedChannel = _a.selectedChannel, channels = _a.channels;
    var channel = channels.filter(function (obj) { return obj.id === selectedChannel; });
    return (_jsx(React.Fragment, { children: _jsx("div", __assign({ className: "pr-header" }, { children: _jsx("p", __assign({ className: "fw-bold" }, { children: channel[0] && channel[0].name })) })) }));
};
