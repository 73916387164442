var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { ThreadForm } from '../Forms/ThreadForm';
import PublicRoomContext from '../../../../application/Context/PublicRoomContext';
import { AlertComponent } from '../../shared-components/AlertMessages/AlertComponent';
import { useTranslation } from "react-i18next";
export var CreateThread = function () {
    var t = useTranslation(['public-room']).t;
    var _a = useContext(PublicRoomContext), modalIsOpen = _a.modalIsOpen, showAlertMessage = _a.showAlertMessage, handleAlertMessage = _a.handleAlertMessage, handleShowThreadForm = _a.handleShowThreadForm;
    return (_jsxs(React.Fragment, { children: [_jsx("button", __assign({ className: "btn btn-primary forum my-4", onClick: handleShowThreadForm }, { children: t("Créer un nouveau sujet") })), modalIsOpen && _jsx(ThreadForm, {}), showAlertMessage && _jsx(AlertComponent, { handleClick: handleAlertMessage })] }));
};
